// 
// backgrounds.scss
//

@each $name,
$value in $theme-colors {
    .bg-#{$name} {
        background-color: #{$value} !important;
    }
    .bg-soft-#{$name} {
        background-color: rgba($value, 0.1) !important;
        border: 1px solid rgba($value, 0.1) !important;
        color: #{$value} !important;
    }
}

.bg-white-color {
    background-color: $bg-white-color !important;
}