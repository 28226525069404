//
// testi.scss
//
.tns-nav {
    text-align: center;
    margin-top: 10px;
    button {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: rgba($primary, 0.5) !important;
        transition: all 0.5s ease;
        border: 2px solid rgba($testi-before-border, 0.5);
        margin: 0 4px;
        padding: 0;
        &.tns-nav-active {
            background: $primary !important;
            border-color: $primary;
        }
    }
}

//Roadmap
.tiny-timeline {
    position: relative;
    width: 100%;
    margin: 0 auto;
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        top: 17px;
        left: 0;
        height: 2px;
        background: $primary;
    }
    .item-box {
        margin: 70px 15px;
        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            background: $primary;
        }
        &:before {
            width: 2px;
            height: 46px;
            top: -46px;
            transform: translateX(-50%);
        }
        &:after {
            width: 13px;
            height: 13px;
            top: -59px;
            border-radius: 3px;
            transform: translateX(-50%) rotate(45deg);
        }
    }
}
