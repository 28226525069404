//
// _calendar.scss
//

:root {
  --fc-event-bg-color: #cbc5c5;
  --fc-event-border-color: #cbc5c5;
  --fc-event-border-color-ACTIVE: #28a745;
  --fc-event-border-color-PENDING: #6c757d;
  --fc-event-border-color-CANCELED: #dc3545;
}

.fc-event-title-container,
#external-events .fc-event {
  cursor: pointer;
  margin: 4px 2px;
}
#external-events {
  .fc-event {
    .fc-event-main {
      padding: 5px;
    }
  }
}

.fc {
  //.fc-non-business {
  //    background-color: transparent;
  //}

  .restricted-time-cell-is-Today {
    opacity: 1;
  }

  .fc-daygrid-day-number {
    padding: 5px;
    font-weight: 600;
    color: $primary;
  }

  .fc-col-header-cell-cushion {
    padding: 8px 4px !important;
    color: $primary;
  }

  .fc-scrollgrid-liquid {
    border-radius: 5px !important;
  }

  .fc-timegrid-slot {
    height: 3em;
  }
}

.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: $border-color !important;
}

.calendar-layout {
  padding-top: 70px !important;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: $gray-200;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-500;
    border-radius: 10px;
    transition: background 0.3s ease;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $gray-600;
  }

  .fc-scrollgrid-section-header .fc-scroller {
    overflow: hidden !important;
  }
}

.fc-header-toolbar {
  .fc-toolbar-chunk {
    .fc-toolbar-title {
      font-size: 18px !important;
    }
  }
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  margin: auto 8px 4px;
}

.fc-daygrid-dot-event {
  background: $gray-100;
  border: 1px solid $border-color;
  &:hover {
    background-color: $gray-200;
  }
}

.fc {
  .fc-button {
    padding: 4px 8px;
    font-size: 13px !important;
    background-color: $primary !important;
    border: 1px solid $primary !important;
    color: $white !important;
    text-transform: capitalize !important;
    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:active,
    &:disabled.active,
    &:disabled.focus {
      background-color: rgba($primary, 0.1) !important;
      border: 1px solid rgba($primary, 0.1) !important;
      color: $primary !important;
    }
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: darken($primary, 5%) !important;
      border-color: darken($primary, 5%) !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  .fc-icon {
    font-size: 16px !important;
  }
  .unavailable-day {
    background-color: #f2f2f2 !important;
  }
}

.fc-h-event,
.fc-daygrid-event-dot {
  border-color: $primary;
  background-color: $primary;
}

#calendar {
  max-width: 1240px;
}

.fc-daygrid-day-frame:hover {
  cursor: pointer;
}

.fc-custom-event-class-overflow-y {
  overflow-y: scroll;
}

.fc-custom-event-class-overflow-x {
  overflow-x: scroll;
}

.fc-timegrid-event-harness {
  box-shadow: 0 0 0 2px var(--fc-page-bg-color);
}

.fc-custom-event-class {
  border-width: 4px;
  font-size: 12px;
  padding: 2px 5px;
  margin: 0;
  box-shadow: inset 0 0 0 3px var(--fc-page-bg-color) !important;
  background: lightgray;

  &:focus-visible {
    outline: none;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  .fc-event-overflow {
    overflow: visible !important;
  }

  .fc-event-title-container {
    margin: 0;
  }
}
