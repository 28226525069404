//
// home.scss
//

@mixin home-common {
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
}
.bg-home {
    height: 100vh;    
    @include home-common();
}
.bg-half-260 {
    padding: 260px 0;    
    @include home-common();
}
.bg-half-170 {
    padding: 170px 0;    
    @include home-common();
}

.bg-half-150 {
    padding: 150px 0;
    @include home-common();    
}

.bg-profile {
    padding: 80px 0;
    @include home-common();
}

.bg-dashboard {
    padding: 100px 0 30px;
    @include home-common();
}

.bg-hero {
    padding: 100px 0;
    @include home-common();    
}

//Doctor profile
.dr-profile-img {
    position: absolute;
    bottom: 0;
    @media (max-width: 425px) {
        position: relative;
        padding: 24px;
    }
}

//Pharmacy Landing
.home-slider {
    .carousel-control-next,
    .carousel-control-prev {
        width: 30px;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 30px;
        border: 1px solid $gray-400;
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        width: 30%;
    }
    .carousel-control-next {
        right: 8px;
    }
    .carousel-control-prev {
        left: 8px;
    }
}

.carousel-indicators {
    [data-bs-target] {
        width: 7px;
        height: 7px;
        border-radius: 30px;
        background: rgba($primary, 0.5) !important;
        transition: all 0.5s ease;
        border: 2px solid rgba($white, 0.5);
        margin: 0 4px;
        padding: 0;
    }
    .active {
        background: $primary !important;
        border-color: $primary;
    }
}

@media (max-width: 767px) {
    .bg-home, .bg-half-170, .bg-half-260, .main-slider .slides .bg-slider {        
        padding: 150px 0;
        height: auto;
    }
}