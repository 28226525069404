//Admin

//
//_menu.scss
//

.page-wrapper {
  height: 100vh;
  .sidebar-wrapper {
    box-shadow: $shadow;
    background-color: $sidebar-white-bg;
    transition: all 0.3s ease;
    width: $menu-width;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;

    @media (min-width: 1025px) {
      z-index: 1000;
    }

    @media (max-width: 1024px) {
      left: 0;
    }

    @media (max-width: 425px) {
      width: 260px;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    a {
      text-decoration: none;
    }
    .sidebar-content {
      max-height: calc(100% - 30px);
      height: calc(100% - 30px);
      overflow-y: auto;
      position: relative;
      &.desktop {
        overflow-y: hidden;
      }
    }
    .sidebar-brand {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      height: 71px;
      > a {
        text-transform: uppercase;
        font-weight: bold;
        flex-grow: 1;
        transition: all 0.3s ease;
        &:focus {
          outline: none;
        }
      }
      #close-sidebar {
        cursor: pointer;
        font-size: 24px;
        transition: all 0.3s ease;
      }
    }
    .sidebar-header {
      padding: 20px;
      overflow: hidden;
    }

    .sidebar-menu {
      padding: 16px 0;
      .header-menu {
        span {
          font-weight: bold;
          font-size: 14px;
          padding: 15px 20px 5px 20px;
          display: inline-block;
        }
      }

      li {
        a {
          display: inline-block;
          width: 100%;
          text-decoration: none;
          position: relative;
          padding: 8px 24px 8px 20px;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;

          @media (max-width: 1024px) {
            padding: 8px 24px 8px 24px;
          }

          @media screen and (max-width: 425px) {
            padding: 8px 24px 8px 12px;
          }
          &:hover > i::before {
            display: inline-block;
          }
          &:focus {
            outline: none;
          }
          .icon {
            width: 36px;
            height: 36px;
            background-color: $sidebar-icon-bg;
            display: inline-flex !important;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            text-align: center;
            border-radius: 5px;
            transition: all 0.3s ease;
            padding: 11px;
          }
        }
        &:hover,
        &.active {
          i {
            background-color: darken($sidebar-icon-bg, 1%);
          }
        }
      }

      .sidebar-dropdown {
        > a:after {
          content: "\F0142" !important;
          color: $dark;
          font-family: "Material Design Icons";
          font-size: 14px;
          position: absolute;
          right: 15px;
          top: 17px;
          transition: all 0.3s ease;
        }
        &.active {
          > a:after {
            transform: rotate(90deg);
            right: 17px;
          }
        }
        &:hover,
        &.active {
          > a:after {
            color: $sidebar-primary;
          }
        }

        .sidebar-submenu {
          display: none;
          ul {
            padding: 5px 0;
            li {
              padding-left: 25px;
              font-size: 14px;
              a {
                &:before {
                  content: "\F035F" !important;
                  color: $dark;
                  font-family: "Material Design Icons";
                  margin-right: 4px;
                  font-size: 16px;
                  transition: all 0.3s ease;
                }
                .badge,
                .label {
                  float: right;
                  margin-top: 0px;
                }
              }
            }
          }
        }
      }
      ul {
        li {
          a {
            span {
              &.label,
              &.badge {
                float: right;
                margin-top: 8px;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }

    &.sidebar-dark {
      background-color: $sidebar-dark;
    }
    &.sidebar-colored {
      background-color: $sidebar-primary;
    }

    &.sidebar-colored,
    &.sidebar-dark {
      .sidebar-brand {
        .sidebar-colored {
          display: inline-block;
        }
        .logo-dark-mode {
          display: none !important;
        }
        .logo-light-mode {
          display: none !important;
        }
      }

      .btn {
        &.btn-icon {
          background-color: rgba($sidebar-light-icon, 0.03) !important;
          color: rgba($sidebar-light-icon, 0.5) !important;
          border: none !important;
          box-shadow: none;
        }
      }

      .sidebar-header,
      .sidebar-menu {
        border-top-color: rgba($white, 0.03);
      }
      .sidebar-header .user-info .user-role,
      .sidebar-header .user-info .user-status,
      .sidebar-brand > a,
      .sidebar-menu li a {
        color: rgba($white, 0.5);
      }
      .sidebar-menu li:hover > a,
      .sidebar-menu li.active > a,
      .sidebar-menu li:active > a,
      .sidebar-menu li:active > a:before,
      .sidebar-menu li:hover > a:before,
      .sidebar-menu li.active > a:before,
      .sidebar-menu .sidebar-dropdown.active > a,
      .sidebar-header .user-info,
      .sidebar-brand > a:hover {
        color: $white !important;
      }
      .sidebar-menu {
        li {
          a {
            .icon {
              background-color: rgba($sidebar-light-icon, 0.03);
            }
          }
          &:hover,
          &.active {
            i {
              background-color: rgba($sidebar-light-icon, 0.05);
            }
          }
        }

        .sidebar-dropdown {
          > a:after {
            color: rgba($white, 0.1);
          }
          &:hover,
          &.active {
            > a:after {
              color: $white;
            }
          }

          .sidebar-submenu {
            ul {
              li {
                a {
                  &:before {
                    color: rgba($white, 0.5);
                  }
                }
              }
            }
          }
        }
      }
    }
    .sidebar-header,
    .sidebar-menu {
      border-top: 1px solid $gray-200;
    }
    .sidebar-header .user-info .user-role,
    .sidebar-header .user-info .user-status,
    .sidebar-brand > a,
    .sidebar-menu li a {
      color: $sidebar-text-color;
      font-weight: 500;
    }
    .sidebar-menu li:hover > a,
    .sidebar-menu li.active > a,
    .sidebar-menu li:active > a,
    .sidebar-menu li:active > a:before,
    .sidebar-menu li:hover > a:before,
    .sidebar-menu li.active > a:before,
    .sidebar-menu .sidebar-dropdown.active > a,
    .sidebar-header .user-info,
    .sidebar-brand > a:hover {
      color: $sidebar-primary !important;
    }

    .sidebar-brand {
      .sidebar-colored {
        display: none;
      }
      .logo-dark-mode {
        display: none;
      }
      .logo-light-mode {
        display: inline-block;
      }
    }
  }

  .page-content {
    display: inline-block;
    width: 100%;
    padding-left: 0px;
    overflow-x: hidden;
    transition: all 0.3s ease;
    .top-header {
      position: fixed;
      right: 0;
      top: 0;
      z-index: 999;
      max-height: 70px;
      left: 0px;
      transition: all 0.3s;
      .header-bar {
        background: $top-header-white-bg;
        height: 71px;
        box-shadow: $shadow;
        position: relative;
        justify-content: space-between;
        align-items: center;
        padding: 24px;

        @media (max-width: 425px) {
          padding: 24px 12px;
        }

        .logo-icon {
          display: none;
          @media (max-width: 1024px) {
            display: block;
          }
          .small {
            display: none;
            @media (max-width: 767px) {
              display: block;
            }
          }
          .big {
            display: none;
            .logo-dark-mode {
              display: none;
            }
            .logo-light-mode {
              display: inline-block;
            }
            @media (min-width: 768px) and (max-width: 1024px) {
              display: block;
            }
          }
        }

        //Menu Search bar
        .search-bar {
          padding-top: 14.5px;
          padding-bottom: 14.5px;
          .menu-search {
            form {
              position: relative;
            }
          }
          .menu-search,
          .searchform {
            input[type="text"] {
              box-shadow: none;
              padding: 10px 12px;
              height: 40px;
              font-size: 14px;
              display: block;
              outline: none !important;
              padding-right: 45px;
              opacity: 0.7;
            }
            input[type="submit"] {
              position: absolute;
              top: 5px;
              right: 10px;
              opacity: 0;
              width: 40px;
              height: 40px;
            }
          }
          .menu-search {
            .searchform:after {
              content: "\F0349";
              position: absolute;
              font-family: "Material Design Icons";
              right: 14px;
              top: 10px;
              font-size: 20px;
              line-height: 20px;
              pointer-events: none;
            }
          }
        }
      }
    }

    .layout-specing {
      padding: 94px 14px 24px !important;
      min-height: calc(100vh - 66px) !important;
      position: relative;
      display: flex;
      flex-direction: column;

      @media (max-width: 425px) {
        padding: 94px 0 24px !important;
      }
    }
  }

  &.toggled {
    @media screen and (min-width: 1025px) {
      .page-content {
        padding-left: $menu-width;
      }
    }
    .top-header {
      left: $menu-width + 1;
      @media (max-width: 1024px) {
        left: 0;
      }
    }
    .sidebar-wrapper {
      left: 0px;
      @media (max-width: 1024px) {
        left: -$menu-width;
      }
    }
  }
}

.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 9px 24px 9px 20px !important;
  box-shadow: $shadow;
  height: 58px;
  @media (max-width: 425px) {
    padding: 9px 12px !important;
  }
}

[dir="rtl"] {
  .page-wrapper {
    .sidebar-wrapper {
      .sidebar-menu {
        .sidebar-dropdown {
          > a:after {
            transform: rotate(-180deg);
          }

          &.active {
            > a:after {
              transform: rotate(-90deg);
            }
          }

          .sidebar-submenu {
            ul {
              li {
                a {
                  &:before {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
