//
// _feature.scss
//
.features {
    @each $name,
    $value in $theme-colors {
        &.feature-#{$name} {
            .title {
                &:hover {
                    color: $value !important;
                }
            }
            .icon {
                color: $value !important;
                background-color: rgba($value, 0.05) !important;
            }
            .link {
                color: $value !important;
            }
        }
        &.feature-bg-#{$name} {
            &:hover {
                background-color: $value !important;
            }
        }
    }
}

.features {
    transition: all 0.5s ease;
    .icon {
        height: 65px;
        width: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title {
        font-weight: 500;
    }

    .big-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        opacity: 0.05;
        font-size: 120px;
    }
    .titles,
    .icons,
    .para,
    .big-icon {
        transition: all 0.5s ease;
    }
    &:hover {
        .titles,
        .icons,
        .big-icon {
            color: $white !important;
        }
        .para {
            color: rgba($white, 0.5) !important;
        }
    }
}