@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.blinkingHeart {
  animation: blink 1s infinite linear;
}
