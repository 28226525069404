//
// cta.scss
//
.play-btn {
    height: 78px;
    width: 78px;
    font-size: 30px;
    line-height: 76px;
    border-radius: 50%;
    display: inline-block;
    color: $white;
}
.play-icon {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: 0;
    left: 0;
    text-align: center;
    i {
        height: 75px;
        -webkit-text-stroke: 2px $primary;
        -webkit-text-fill-color: transparent;  
        width: 75px;
        font-size: 25px;
        line-height: 75px;
        display: inline-block;
        background-color: $play-btn-icon;
        &:hover {
            -webkit-text-stroke: 2px $primary;
            -webkit-text-fill-color: $primary;
        }
    }
}

.cta-review {
    position: relative;
    top: 220px;
}