//
// _chat.scss
//

.chat {
    .chat-list {
        &:hover,
        &.active,
        &:focus {
            background: $light !important;
            box-shadow: $shadow;
        }
    }
    
    .chat-list,
    .chat-type {
        .on-off {
            position: absolute;
            left: -1px;
            top: -1px;
            font-size: 12px !important;
        }
    }
    
    &.chat-person {
        .on-off {
            font-size: 10px;
        }
        .chat-msg {
            margin-left: 8px;
            .msg {
                border: 1px solid $gray-200;
            }
            .msg-time {
                font-size: 12px !important;
            }
        }
        .chat-right {
            justify-content: flex-end;
            text-align: right;
            .chat-user-image {
                order: 2;
                .on-off {
                    right: -1px;
                }
            }
            .chat-msg {
                margin-right: 8px;
                margin-left: 0;
                .msg {
                    display: inline-block;
                }
                .msg-time {
                    display: block;
                }
            }
        }
    }
}

.chat-list::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

.chat-list::-webkit-scrollbar-button {
    display: none;
}

.chat-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.chat-list::-webkit-scrollbar-track {
    background: transparent;
}

.chat-list {
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;
}

.custom-chat-msg {
    box-shadow: 0 0 3px rgba(#3c4858, 0.15);
    transition: box-shadow 0.5s;
}

.custom-chat-msg.highlighted {
    box-shadow: 0 0 10px 2px #396cf0;
}

.single-line-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
    cursor: pointer;
}