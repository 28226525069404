.custom-tooltip .tooltip-inner, .custom-tooltip .tooltip-arrow {
  max-width: fit-content;
  --bs-tooltip-bg: #ced4da;
  color: #6c757d;
}

.custom-option-tooltip {
  .tooltip-inner {

  max-width: 450px;
  }
}